<template>
  <div class="container">
    <PublicHeader :totalName="$t('setting').title" />
    <!-- 头像 -->
    <div class="hListBox">
      <div class="hListTxt">
        <div class="qTxt">{{ $t('setting').heard }}</div>
      </div>
      <div class="c-c-c">
        <div class="qTxt1">
          <van-uploader class="img-box" preview-size="120" :preview-image="false" :after-read="justAfterRead">
            <img class="qImg" v-if="userInfo.Head" :src="userInfo.Head" />
            <img class="qImg" v-else src="@/assets/img/heard.png" />
          </van-uploader>
        </div>
        <van-icon color="#999999" name="arrow" size="18" />
      </div>
    </div>
    <!-- 昵称 -->
    <div class="hListBox" @click="showEdit = true">
      <div class="hListTxt">
        <div class="qTxt">{{ $t('setting').nickname }}</div>
      </div>
      <div class="c-c-c">
        <div class="qTxt1">{{ userInfo.NickName }}</div>
        <van-icon color="#999999" name="arrow" size="18" />
      </div>
    </div>
    <!-- 语言 -->
    <div class="hListBox">
      <div class="hListTxt">
        <div class="qTxt">{{ $t('setting').lang }}</div>
      </div>
      <div class="c-c-c" @click="showPopover = !showPopover">
        <div class="qTxt1">
          <van-popover v-model="showPopover" placement="bottom-end" :actions="actions" @select="onSelect">
            <template #reference>
              <span>{{ nowLang }}</span>
            </template>
          </van-popover>
        </div>
        <van-icon color="#999999" name="arrow" size="18" />
      </div>
    </div>
    <!-- 退出 -->
    <div class="out" @click="Logout">{{ $t('setting').outlogin }}</div>
    <!-- 修改昵称 -->
    <van-popup v-model="showEdit" closeable round :style="{ width: '320px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('setting').editNicknmae }}</div>
        <div class="info">
          <van-field v-model="NickName" :placeholder="$t('setting').nicknamemsg"></van-field>
          <div class="btns">
            <div class="btn" @click="showEdit = false">{{ $t('public').cancel }}</div>
            <div class="btn2" @click="subNickName">{{ $t('public').confirm }}</div>
          </div>
        </div>

      </div>

    </van-popup>
  </div>
</template>

<script>
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeader'
import systemApi from '@/api/system'
import userApi from '@/api/user'
import { showName } from '@/utils/validate'
export default {
  name: '',
  components: {
    PublicHeader
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection']),
  },
  data() {
    return {
      showEdit: false,
      NickName: '',
      showPopover: false,
      nowLang: '',
      userInfo: {},
      actions: [
        { text: '简体中文', lang: 'zh' },//简体中心
        { text: 'English', lang: 'en' },//英语
        // { text: '日本語', lang: 'jp' },//日语
        // { text: '한국인', lang: 'hy' },//韩语
        // { text: 'ภาษาไทย', lang: 'ty' },//泰语
        // { text: 'Indonesia', lang: 'yn' },//印尼语
        // { text: 'Việt nam', lang: 'yuenan' },//越南文
        // { text: 'កម្ពុជា', lang: 'gmw' },//高棉文
        // { text: 'Français', lang: 'fy' },//法语
        // { text: 'Español', lang: 'xby' },//西班牙语
        // { text: 'Русский язык', lang: 'ey' },//俄语
        // { text: 'Português', lang: 'pty' },//葡萄牙语
        // { text: 'Deutsch', lang: 'dy' },//德语
      ],
      AboutUsList: [],
      contentId: null
    }
  },
  mounted() {
    this.nowLang = showName(localStorage.getItem('language') || 'zh')
    this.getAccountBase()
  },
  methods: {
    //选择语言
    async onSelect(action) {
      var lang = action.lang
      this.$i18n.locale = lang
      this.$store.commit('SET_LANG', lang)
      localStorage.setItem('language', lang)
      this.nowLang = showName(lang)
      //延时更新用户默认语言
      // setTimeout(() => {
      //   userApi.UpdateLang()
      // }, 300);
    },
    //获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    //上传头像
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('file', file.file)
      this.userInfo.Head = await systemApi.UploadFile(formdata)
      //更改头像
      await userApi.UpdateHead({ Str: this.userInfo.Head })
      this.getAccountBase()
    },
    //修改昵称
    async subNickName() {
      if (!this.NickName) {
        this.$toast({ message: this.$t('setting').nicknamemsg })
        return false
      }
      await userApi.UpdateNickName({ Str: this.NickName })
      this.getAccountBase()
      this.showEdit = false
    },
    //退出登录
    Logout() {
      this.$dialog.confirm({
        title: this.$t('setting').outloginmsg,
        confirmButtonText: this.$t('public').confirm,
        cancelButtonText: this.$t('public').cancel,
      })
        .then(() => {
          Token.remove()
          this.$router.push({ name: 'Home' })
        })
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 70px 15px 0px 15px;
}

.c-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-c-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-c-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-c-row {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.pheader {
  font-size: 30px;
  font-weight: 500;
  color: #515151;
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.hListBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 15px 0px;
  border-bottom: 1px solid #f1f1f1;
}

.hListTxt {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.qImg {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.qTxt {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.qTxt1 {
  font-size: 15px;
  color: #333333;
  margin-right: 10px;
}

.out {
  margin-top: 150px;
  text-align: center;
  font-size: 15px;
  color: #999999;
}

.popInfo {
  .title {
    padding: 20px 0;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    border-bottom: 1px solid #DCDCDC;
  }

  .info {
    padding: 15px 13px 20px 13px;
    text-align: center;

    .tip {
      padding: 10px 0 0 0;
      font-size: 14px;
      color: #FF9B37;
    }

    .btns {
      margin: 20px 13px 0 13px;
      display: flex;
      justify-content: space-between;

      .btn {
        width: 119px;
        height: 40px;
        background: rgba(201, 43, 46, 0);
        border: 1px solid #C92B2E;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #C92B2E;
      }

      .btn2 {
        width: 119px;
        height: 40px;
        background: #C92B2E;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        color: #fff;
      }
    }
  }
}

::v-deep .van-popup__close-icon {
  color: #000000;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}
</style>
